.nav-item {
  display: inline-block;
}

.nav-item:hover .label {
  color: #868686;
}

.nav-item:active .label {
  color: #ff6a04;
}

.nav-item .label {
  text-align: center;
  margin: 0;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #aeaeae;
  font-size: 14px;
  margin-top: 3px;
  cursor: pointer;
}
