.toolbar-photo {
  min-width: 70px;
  min-height: 71px;
  padding: 8px 0 0;
  display: inline-block;
  position: relative;
}

@media print {
  .toolbar-photo {
    display: none;
  }
}

.toolbar-photo .content {
  width: auto;
  display: flex;
  margin: auto;
  max-height: 100%;
  padding: 0 1.5%;
  align-items: center;
  justify-content: center;
}

.toolbar-photo .content-expanded {
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
}

.toolbar-photo .thumb-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  align-items: center;
}

.thumb-list-container {
  display: flex;
  justify-content: flex-start;
}

.thumb-li {
  text-align: center;
}

.thumb-li > span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  vertical-align: bottom;
}

.thumb .image-container:hover,
.thumb .image-container.active:hover {
  background: #ffffff25;
  border-color: #ff9807;
}

.thumb .image-container:active,
.thumb .image-container.active,
.thumb .image-container.active:active {
  background: transparent;
  border-color: #ff6a04;
}

.thumb .image-container:hover .white-layer {
  background: #ffffff25;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  left: 3px;
  top: 3px;
  position: absolute;
  z-index: 1;
}

.thumb .image-container:active .white-layer {
  background: transparent;
}

.thumb .image-container,
.thumb .loading-container {
  width: 90px;
  height: 64px;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

.thumb .loading-container {
  cursor: progress;
}

.thumb-expand {
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  overflow: auto;
}

.btn-close {
  display: flex;
  justify-content: center;
}

.thumb-expand button {
  margin: 0;
}

.thumb-expand .btn-close .button {
  margin-top: 14px;
}

.thumb-expand .btn-navigation {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 50%;
  padding: 0 1.5%;
  z-index: 6;
}

.thumb-expand-container {
  width: 100%;
  position: fixed;
  padding: 1.5% 1.6%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.__react_component_tooltip.show {
  opacity: 1;
}

.__react_component_tooltip.type-dark {
  color: #fff;
  background-color: rgb(20, 172, 0) !important;
  border: 2px solid #fff;
}

.__react_component_tooltip.type-warning {
  color: #fff;
  background-color: rgb(235, 0, 0) !important;
  border: 2px solid #fff;
}

.__react_component_tooltip.type-warning.place-top:after,
.__react_component_tooltip.type-dark.place-top:after {
  border-top-color: rgb(255, 255, 255) !important;
  border-top-style: solid;
  border-top-width: 6px;
}

.__react_component_tooltip.type-warning.place-bottom:after,
.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: rgb(255, 255, 255) !important;
  border-bottom-style: solid;
  border-bottom-width: 6px;
}

.__react_component_tooltip.type-warning.place-left:after,
.__react_component_tooltip.type-dark.place-left:after {
  border-left-color: rgb(255, 255, 255) !important;
  border-left-style: solid;
  border-left-width: 6px;
}

.__react_component_tooltip.type-warning.place-right:after,
.__react_component_tooltip.type-dark.place-right:after {
  border-right-color: rgb(255, 255, 255) !important;
  border-right-style: solid;
  border-right-width: 6px;
}

.toolbar-photo .thumb-list.expanded {
  flex-flow: wrap;
  /* position: absolute; */
  max-width: 100%;
  margin: 8px 0 0;
}

.toolbar-photo .thumb-list.expanded > li {
  margin-bottom: 24px;
}

.toolbar-photo .thumb-list > li {
  display: inline-block;
}

.toolbar-photo .thumb-list.expanded > .toolbar-photo .image-container {
  margin-bottom: 24px;
}

.controls {
  width: auto;
  min-height: 89px;
  margin: 0;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls button {
  margin: 1px;
}

.scrollContainer {
  display: flex;
  justify-content: center;
}

.scrollContainer .button {
  padding: 1px 0;
  margin: 0 5px;
}
