@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

.nav-item-add-photo {
  padding: 8px 0 0;
  display: inline-block;
}

.nav-item-add-photo .label-add-photo {
  display: inline-block;
  /* margin: 0 20px 0 0; */
}

.nav-item-add-photo #image-input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.nav-item-add-photo #image-input-file:focus + .label-add-photo {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.nav-item-add-photo .label-add-photo > span {
  display: block;
  text-align: center;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #a4a4a4;
  font-size: 14px;
  cursor: pointer;
}
.nav-item-add-photo .div-add-menu {
  cursor: pointer;
  display: inline-block;
}
.nav-item-add-photo .bt-menu-add-photo-animated,
.nav-item-add-photo .bt-menu-add-theme-photo-animated {
  animation: pulse 0.6s infinite alternate;
}

.nav-item-add-photo .label-add-photo:hover > span {
  color: #7c7c7c;
}

.nav-item-add-photo .label-add-photo:active > span {
  color: #ff6a04;
}

.nav-item-add-photo .label-add-photo > span[data-animation='true'],
.nav-item-add-photo .label-add-photo > span[data-animation='true']:hover,
.nav-item-add-photo .label-add-photo > span[data-animation='true']:active {
  color: #ff9807;
}

.nav-item-add-photo #group-select {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.nav-item-add-photo #group-select:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
