.layouts-box {
  display: flex;
  height: 100%;
  align-items: center;
}

.layouts-box button {
  margin-top: 19px;
}

.layouts-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 2px;
  padding-left: 2px;
}

.layouts-container > div {
  height: 100%;
}

.layouts-container .checked {
  display: block;
  margin-top: -1px;
  margin-bottom: 11px;
}

.layouts-container .checked + .shape-box {
  margin-top: 10px;
}

.shape-box {
  position: relative;
  background: white;
  height: 40px;
  outline: 1px solid #ccc;
  margin-top: 24px;
  cursor: pointer;
}

.shape-box.active,
.shape-box.active:hover {
  outline-color: #ff9807;
  outline-width: 2px;
}

.shape-box.active .shape,
.shape-box.active:hover .shape {
  fill: #ff6a04;
}

.shape-box.active .text-area,
.shape-box.active:hover .text-area,
.shape-box:active .text-area {
  background: repeating-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1) 50px,
    rgba(255, 106, 4, 1) 50px,
    rgba(255, 106, 4, 1) 100px
  );
}

.shape-box:hover {
  outline-color: #868686;
}

.shape-box:hover .shape {
  fill: #7c7c7c;
}

.shape-box:hover .text-area {
  background: repeating-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 1) 50px,
    rgba(124, 124, 124, 1) 50px,
    rgba(124, 124, 124, 1) 100px
  );
}

.shape-box:active {
  outline-color: #ff9807;
  outline-width: 2px;
}

.shape-box:active .shape {
  fill: #ff6a04;
}

.shape {
  fill: #aeaeae;
}

.text-area {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 1) 50px,
    rgba(174, 174, 174, 1) 50px,
    rgba(174, 174, 174, 1) 100px
  );
}
