.nav-text {
  padding: 15px 0;
  display: flex;
}

.nav-text button {
  margin: 0;
}

.border-container {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}

.font-family-container {
  min-width: 230px;
  width: 230px;
  height: 64px;
}

.font-size-container {
  min-width: 80px;
  width: 80px;
  height: 64px;
}

.font-size-container .font-option-box span {
  padding-bottom: 1px;
}

.font-option-box {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  color: rgb(164, 164, 164);
  padding: 0 9px;
  z-index: 1;
}

.font-option-box span {
  height: 100%;
  display: flex;
  align-items: center;
}

.font-option-box:nth-child(2) {
  height: 22px;
}

.font-option-box:hover {
  color: rgb(124, 124, 124);
  background-color: #d7d7d7;
}

.font-option-box:active {
  color: #ff6a04;
}

.font-option-box.active,
.font-option-box.active:hover,
.font-option-box.active:active {
  color: #ff6a04;
  background-color: white;
}

.font-option-box.active .icon-check {
  margin-right: 19px;
}

.text-style-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-style-container div {
  display: flex;
}

.text-style-container button {
  margin-left: -1px;
}

.text-decoration-container div:first-child button,
.text-align-container div:first-child button {
  margin: 0;
}

.colors-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colors-container button {
  margin-top: 2px;
}

.colors-box {
  width: 100%;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
}

.color {
  width: 26px;
  height: 26px;
  border-radius: 14px;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 0px 1px #ccc;
  cursor: pointer;
}

.color:hover {
  box-shadow: 0px 0px 0px 1px #868686;
}

.color:active,
.color.active {
  box-shadow: 0 0 0 2px #ff9807;
}

.color-box {
  display: flex;
  justify-content: center;
  padding: 1px 0;
}

.color-box.bottom {
  align-items: flex-end;
}

.colors-box .icon-check.top {
  position: absolute;
  top: 0px;
}

.colors-box .icon-check.bottom {
  position: absolute;
  bottom: 0px;
}

.colors-box .icon-check.none {
  display: none;
}

.disable-font-size {
  width: 80px;
  height: 64px;
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  margin-top: -1px;
  margin-left: -1px;
  z-index: 1;
}

.disabled {
  pointer-events: none;
  opacity: 0.25;
}
