.switch input {
  opacity: 0;
}

.switch .container {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
  top: 2px;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00a9e7;
  border-radius: 100px;
}

.switch .slider:before {
  position: absolute;
  content: '';
  width: 11px;
  height: 11px;
  left: 0px;
  border-radius: 50%;
  background: #fff;
  transition: transform 0.15s;
  border: 2.5px solid #00a9e7;
}

.switch .container input:hover + .slider:before {
  border: 2.5px solid #ff9807;
}

.switch .container input:hover + .slider {
  background: #ff9807;
}

.switch input:checked + .slider {
  background: #00b22d;
}

.switch input:checked + .slider:before {
  border: 2.5px solid #00b22d;
  transform: translate(14px, 0);
}

.switch .description {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #868686;
  font-size: 16px;
  opacity: 0.54;
  margin: 0;
  margin-left: 4px;
}

span.switch-span {
  padding-left: 4px;
  font-size: 16px;
  color: #a4a4a4;
  font-weight: bold;
}

span.switch-span.switch-active {
  color: #868686;
  animation: blink 1s infinite;
}

.switch .description,
.switch .slider {
  display: inline-block;
}

@-webkit-keyframes cover-effect-loop {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.64;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes cover-effect-loop {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.64;
  }
  100% {
    opacity: 0.25;
  }
}

.switch .description.highlight {
  -webkit-animation: cover-effect-loop 1.25s ease-in-out infinite;
  animation: cover-effect-loop 1.25s ease-in-out infinite;
}
