.container-biblioteca{
  display: grid;
  grid-template-columns: 27px 1fr 27px;
  align-self: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding-right: 21px;
}

.container-biblioteca > .left-button{
  display: flex;
  align-items: center;
  align-content: center;
}

.container-biblioteca > .right-button{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: center;
}

.container-biblioteca > .biblioteca-imagens {
  display: inline-flex;
  align-self: center;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  grid-gap: 0 0.8vw;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle {
  display: block;
  height: 100%;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle .item-img{
  opacity: 1;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle .item-img:hover{
  opacity: 0.75;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle > .item-img{
  height: 60px;
  width: fit-content;
  position: relative;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle > .item-img > .botao-delete{
  align-items: center;
  align-self: center;
  text-align: center;
  width: 100%;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle > .item-img > .botao-delete > .bt-menu-delete{
  position: absolute;
  cursor: pointer;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 2px;
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle > .contador-img{
  position: relative;
  height: 20px;
  font-family: lato;
  font-size: 14px;
  color: rgb(164,164,164);
}

.container-biblioteca > .biblioteca-imagens > .item-rectangle > .contador-img > .contador-img-number{
  position: absolute;
  font-weight: 400;
  height: 100%;
  top: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}