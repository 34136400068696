.bt-bottom-arrow-white,
.bt-left-arrow-black,
.bt-left-arrow-black-disabled,
.bt-left-arrow-white-expand,
.bt-left-arrow-white,
.bt-left-double-arrow-white,
.bt-menu-add-photo,
.bt-menu-add-photo:hover,
.bt-menu-add-photo-animated,
.bt-menu-book,
.bt-menu-book-pressed,
.bt-menu-book:active,
.bt-menu-book:hover,
.bt-menu-clear-photo,
.bt-menu-delete,
.bt-menu-fill-photo,
.bt-menu-group-photo,
.bt-menu-photo,
.bt-menu-photo-pressed,
.bt-menu-photo:active,
.bt-menu-photo:hover,
.bt-menu-plus-one,
.bt-menu-plus-one:active,
.bt-menu-plus-one:hover,
.bt-menu-remove-photo,
.bt-menu-rotate,
.bt-menu-rotate-disabled,
.bt-menu-rotate:active,
.bt-menu-rotate:hover,
.bt-menu-shuffle-next,
.bt-menu-shuffle-next:active,
.bt-menu-shuffle-next:hover,
.bt-menu-shuffle-next-inactive,
.bt-menu-shuffle-previous,
.bt-menu-shuffle-previous:active,
.bt-menu-shuffle-previous:hover,
.bt-menu-shuffle-previous-inactive,
.bt-menu-sort-photo,
.bt-menu-style-manager,
.bt-menu-style-manager-pressed,
.bt-menu-style-manager:active,
.bt-menu-style-manager:hover,
.bt-menu-zoom-in,
.bt-menu-zoom-in-disabled,
.bt-menu-zoom-in:active,
.bt-menu-zoom-in:hover,
.bt-menu-zoom-out,
.bt-menu-zoom-out-disabled,
.bt-menu-zoom-out:active,
.bt-menu-zoom-out:hover,
.bt-redo-white,
.bt-right-arrow-black,
.bt-right-arrow-black-disabled,
.bt-right-arrow-white,
.bt-right-arrow-white-expand,
.bt-right-arrow-white-expand-modal,
.bt-menu-selected,
.bt-menu-selected-double,
.bt-right-double-arrow-white,
.bt-top-arrow-white,
.bt-undo-white,
.bt-editor-close,
.bt-editor-close-save,
.bt-editor-remove,
.bt-editor-remove-inactive,
.bt-editor-zoom-in,
.bt-editor-zoom-out,
.bt-editor-rotate,
.bt-overview-add,
.bt-overview-add-inactive,
.bt-overview-move-right-inactive,
.bt-overview-move-right,
.bt-overview-move-left-inactive,
.bt-overview-move-left,
.green-arrow-3d,
.green-arrow-done,
.green-arrow-pages,
.icon-check,
.icon-group-all-photos,
.icon-group-all-photos-checked,
.icon-group-manual-photos,
.icon-group-manual-photos-checked,
.icon-group-multi-used-photos,
.icon-group-multi-used-photos-checked,
.icon-group-not-used,
.icon-group-not-used-checked,
.icon-group-used-photos,
.icon-group-used-photos-checked,
.icon-sort-alfabetic,
.icon-sort-alfabetic-checked,
.icon-sort-date,
.icon-sort-date-checked,
.icon-text-align-left,
.icon-text-align-left-active,
.icon-text-align-center,
.icon-text-align-center-active,
.icon-text-align-right,
.icon-text-align-right-active,
.icon-text-align-justify,
.icon-text-align-justify-active,
.icon-text-decoration-bold,
.icon-text-decoration-bold-active,
.icon-text-decoration-italic,
.icon-text-decoration-italic-active,
.icon-text-decoration-underline,
.icon-text-decoration-underline-active,
.photo-bleed-cut,
.photo-bleed-fill,
.photo-padding-bg {
  background-image: url('atlas@2x.png');
  background-repeat: no-repeat;
  -webkit-background-size: 444px 752px;
  -moz-background-size: 444px 752px;
  background-size: 444px 752px;
}

.photo-bleed-fill {
  background-position: 0 0;
  width: 222px;
  height: 124px;
}

.photo-bleed-cut {
  background-position: -222px 0;
  width: 222px;
  height: 124px;
}

.bt-menu-sort-photo {
  background-position: -274px -359px;
  width: 82px;
  height: 60px;
}

.cover:hover .bt-menu-sort-photo {
  background-position: -274px -421px;
}

.cover:active .bt-menu-sort-photo {
  background-position: -274px -483px;
}

.bt-menu-group-photo {
  background-position: -358px -359px;
  width: 82px;
  height: 60px;
}

.cover:hover .bt-menu-group-photo {
  background-position: -358px -421px;
}

.cover:active .bt-menu-group-photo {
  background-position: -358px -483px;
}

.green-arrow-pages {
  background-position: 0 -180px;
  width: 39px;
  height: 76px;
}

.green-arrow-done {
  background-position: -39px -180px;
  width: 39px;
  height: 76px;
}

.green-arrow-3d {
  background-position: -77px -180px;
  width: 39px;
  height: 76px;
}

.bt-menu-remove-photo {
  background-position: -117px -180px;
  width: 66px;
  height: 54px;
}

label:hover .bt-menu-remove-photo {
  background-position: -138px -452px;
}

label:active .bt-menu-remove-photo {
  background-position: -206px -452px;
}

.bt-menu-fill-photo {
  background-position: -163px -123px;
  width: 66px;
  height: 54px;
}

label:hover .bt-menu-fill-photo {
  background-position: -138px -396px;
}

label:active .bt-menu-fill-photo {
  background-position: -206px -396px;
}

.bt-menu-clear-photo {
  background-position: -232px -123px;
  width: 66px;
  height: 54px;
}

label:hover .bt-menu-clear-photo {
  background-position: -2px -396px;
}

label:active .bt-menu-clear-photo {
  background-position: -70px -396px;
}

.bt-menu-add-photo {
  background-position: -257px -180px;
  width: 66px;
  height: 54px;
}

.bt-menu-add-photo-animated {
  background-position: -187px -180px;
  width: 66px;
  height: 54px;
}

label:hover .bt-menu-add-photo {
  background-position: -2px -452px;
}

label:active .bt-menu-add-photo {
  background-position: -70px -452px;
}

.photo-padding-bg {
  background-position: -302px -124px;
  width: 64px;
  height: 49px;
}

.bt-menu-style-manager:hover {
  background-position: -365px -123px;
  width: 45px;
  height: 41px;
}

.bt-menu-style-manager:active {
  background-position: -324px -179px;
  width: 45px;
  height: 41px;
}

.bt-menu-style-manager {
  background-position: -369px -179px;
  width: 45px;
  height: 41px;
}

.bt-menu-style-manager-pressed {
  background-position: -324px -179px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-previous:hover {
  background-position: 1px -255px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-previous:active {
  background-position: -43px -255px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-previous {
  background-position: -88px -255px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-previous-inactive {
  background-position: -88px -255px;
  width: 45px;
  height: 41px;
  opacity: 0.25;
}

.bt-menu-photo:hover {
  background-position: -135px -258px;
  width: 40px;
  height: 33px;
}

.bt-menu-photo:active {
  background-position: -180px -258px;
  width: 40px;
  height: 33px;
}

.bt-menu-photo {
  background-position: -224px -258px;
  width: 40px;
  height: 33px;
}

.bt-menu-photo-pressed {
  background-position: -180px -258px;
  width: 40px;
  height: 33px;
}

.bt-menu-book:hover {
  background-position: -311px -257px;
}

.bt-menu-book:active {
  background-position: -355px -257px;
}

.bt-menu-book {
  background-position: -400px -257px;
  width: 44px;
  height: 39px;
}

.bt-menu-book-pressed {
  background-position: -355px -257px;
  width: 44px;
  height: 39px;
}

.bt-menu-shuffle-next:hover {
  background-position: -88px -295px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-next:active {
  background-position: -132px -295px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-next {
  background-position: -176px -295px;
  width: 45px;
  height: 41px;
}

.bt-menu-shuffle-next-inactive {
  background-position: -176px -295px;
  width: 45px;
  height: 41px;
  opacity: 0.25;
}

.icon-sort-date {
  background-position: -5px -297px;
  width: 33px;
  height: 26px;
}

label:hover .icon-sort-date {
  background-position: -70px -508px;
}

label:active .icon-sort-date {
  background-position: -70px -536px;
}

.icon-sort-date-checked {
  background-position: -70px -536px;
  width: 33px;
  height: 26px;
}

.icon-sort-alfabetic {
  background-position: -223px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-sort-alfabetic {
  background-position: -2px -508px;
}

label:active .icon-sort-alfabetic {
  background-position: -2px -536px;
}

.icon-sort-alfabetic-checked {
  background-position: -2px -536px;
  width: 33px;
  height: 26px;
}

.icon-group-used-photos {
  background-position: -260px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-used-photos {
  background-position: -72px -123px;
}

label:active .icon-group-used-photos {
  background-position: -72px -151px;
}

.icon-group-used-photos-checked {
  background-position: -72px -151px;
  width: 33px;
  height: 26px;
}

.icon-group-not-used {
  background-position: -297px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-not-used {
  background-position: -2px -123px;
}

label:active .icon-group-not-used {
  background-position: -2px -151px;
}

.icon-group-not-used-checked {
  background-position: -2px -151px;
  width: 33px;
  height: 26px;
}

.icon-group-multi-used-photos {
  background-position: -334px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-multi-used-photos {
  background-position: -107px -123px;
}

label:active .icon-group-multi-used-photos {
  background-position: -107px -151px;
}

.icon-group-multi-used-photos-checked {
  background-position: -107px -151px;
  width: 33px;
  height: 26px;
}

.icon-group-manual-photos {
  background-position: -371px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-manual-photos {
  background-position: -37px -508px;
}

label:active .icon-group-manual-photos {
  background-position: -37px -536px;
}

.icon-group-manual-photos-checked {
  background-position: -37px -536px;
  width: 33px;
  height: 26px;
}

.icon-group-all-photos {
  background-position: -408px -296px;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-all-photos {
  background-position: -37px -123px;
}

label:active .icon-group-all-photos {
  background-position: -37px -151px;
}

.icon-group-all-photos-checked {
  background-position: -37px -151px;
  width: 33px;
  height: 26px;
}

.bt-undo-white {
  background-position: -365px -218px;
  width: 24px;
  height: 28px;
}

.bt-redo-white {
  background-position: -394px -218px;
  width: 24px;
  height: 28px;
}

.bt-top-arrow-white,
.bt-top-arrow-white:disabled:hover {
  background-position: -412px -126px;
  width: 24px;
  height: 10px;
  opacity: 0.5;
}

.bt-top-arrow-white:hover {
  background-position: -91px -367px;
  opacity: 1;
}

.bt-top-arrow-white:active {
  background-position: -207px -367px;
  opacity: 1;
}

.bt-right-double-arrow-white,
.bt-right-double-arrow-white:disabled:hover {
  background-position: -415px -180px;
  width: 28px;
  height: 28px;
  opacity: 0.5;
}

.bt-right-double-arrow-white:hover {
  background-position: -60px -367px;
  opacity: 1;
}

.bt-right-double-arrow-white:active {
  background-position: -177px -367px;
  opacity: 1;
}

.bt-right-arrow-white,
.bt-right-arrow-white:disabled:hover {
  background-position: 0 -337px;
  width: 16px;
  height: 28px;
  opacity: 0.5;
}

.bt-right-arrow-white-expand,
.bt-left-arrow-white-expand {
  background-position: -2px -678px;
  width: 36px;
  height: 72px;
  opacity: 0.5;
}

.bt-right-arrow-white-expand-modal {
  background-position: -2px -678px;
  width: 36px;
  height: 72px;
  opacity: 0.75;
}

.bt-left-arrow-white-expand {
  transform: rotate(180deg);
}

.bt-right-arrow-white-expand:hover,
.bt-left-arrow-white-expand:hover,
.bt-right-arrow-white-expand-modal:hover {
  background-position: -40px -678px;
  opacity: 1;
}

.bt-right-arrow-white-expand:active,
.bt-left-arrow-white-expand:active,
.bt-right-arrow-white-expand-modal:active {
  background-position: -78px -678px;
  opacity: 1;
}

.bt-right-arrow-white:hover {
  background-position: 0 -367px;
  opacity: 1;
}

.bt-right-arrow-white:active {
  background-position: -117px -367px;
  opacity: 1;
}

.bt-right-arrow-black-disabled{
  background-position: -256px -367px;
  min-width: 12px;
  height: 24px;
  opacity: 0.05;
  cursor: normal;
  pointer-events: none;
}

.bt-right-arrow-black{
  background-position: -256px -367px;
  min-width: 12px;
  height: 24px;
  opacity: 0.2;
}

.bt-right-arrow-black:hover {
  background-position: -2px -367px;
  opacity: 1;
}

.bt-right-arrow-black:active {
  background-position: -119px -367px;
  opacity: 1;
}

.bt-left-double-arrow-white,
.bt-left-double-arrow-white:disabled:hover {
  background-position: -16px -337px;
  width: 28px;
  height: 28px;
  opacity: 0.5;
}

.bt-left-double-arrow-white:hover {
  background-position: -16px -367px;
  opacity: 1;
}

.bt-left-double-arrow-white:active {
  background-position: -133px -367px;
  opacity: 1;
}

.bt-left-arrow-white,
.bt-left-arrow-white:disabled:hover {
  background-position: -44px -337px;
  width: 16px;
  height: 28px;
  opacity: 0.5;
}

.bt-left-arrow-white:hover {
  background-position: -44px -367px;
  opacity: 1;
}

.bt-left-arrow-black-disabled{
  background-position: -238px -367px;
  width: 12px;
  height: 24px;
  opacity: 0.05;
  cursor: normal;
  pointer-events: none;
}

.bt-left-arrow-black{
  background-position: -238px -367px;
  width: 12px;
  height: 24px;
  opacity: 0.2;
}

.bt-left-arrow-black:hover {
  background-position: -46px -367px;
  opacity: 1;
}

.bt-left-arrow-black:active {
  background-position: -163px -367px;
  opacity: 1;
}

.bt-left-arrow-white:active {
  background-position: -161px -367px;
  opacity: 1;
}

.bt-bottom-arrow-white,
.bt-bottom-arrow-white:disabled:hover {
  background-position: -412px -140px;
  width: 24px;
  height: 10px;
  opacity: 0.5;
}

.bt-bottom-arrow-white:hover {
  background-position: -91px -381px;
  opacity: 1;
}

.bt-bottom-arrow-white:active {
  background-position: -207px -381px;
  opacity: 1;
}

.bt-menu-zoom-out:hover {
  background-position: -60px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-out:active {
  background-position: -84px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-out {
  background-position: -108px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-out-disabled {
  background-position: -132px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-in:hover {
  background-position: -156px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-in:active {
  background-position: -180px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-in {
  background-position: -204px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-zoom-in-disabled {
  background-position: -228px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-rotate:hover {
  background-position: -252px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-rotate:active {
  background-position: -276px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-rotate {
  background-position: -300px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-rotate-disabled {
  background-position: -324px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-plus-one:hover {
  background-position: -348px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-plus-one:active {
  background-position: -372px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-plus-one {
  background-position: -396px -337px;
  width: 24px;
  height: 24px;
}

.bt-menu-delete {
  background-position: -373px -220px;
  width: 14px;
  height: 14px;
}

.bt-menu-delete:hover {
  background-position: -373px -237px;
}

.bt-menu-delete:active {
  background-position: -390px -220px;
}

.bt-menu-selected {
  background-position: -390px -237px;
  width: 14px;
  height: 14px;
}

.bt-menu-selected-double {
  background-position: -390px -237px;
  width: 30px;
  height: 14px;
}

.icon-check {
  background-position: -326px -219px;
  width: 17px;
  height: 14px;
}

.bt-editor-close,
.bt-editor-close-save,
.bt-editor-remove,
.bt-editor-zoom-in,
.bt-editor-zoom-out,
.bt-editor-rotate,
.bt-overview-add,
.bt-editor-remove-inactive,
.bt-overview-add-inactive,
.bt-overview-move-right-inactive,
.bt-overview-move-right,
.bt-overview-move-left-inactive,
.bt-overview-move-left {
  width: 36px;
  height: 36px;
}

.bt-overview-move-right-inactive,
.bt-overview-move-left-inactive,
.bt-editor-remove-inactive,
.bt-overview-add-inactive {
  cursor: initial;
}

.bt-editor-close {
  background-position: -160px -507px;
}

.bt-editor-close:hover {
  background-position: -198px -507px;
}

.bt-editor-close:active {
  background-position: -236px -507px;
}

.bt-editor-close-save {
  background-position: -162px -678px;
}

.bt-editor-close-save:hover {
  background-position: -200px -678px;
}

.bt-editor-close-save:active {
  background-position: -238px -678px;
}

.bt-editor-zoom-out,
.bt-editor-zoom-out:disabled:hover {
  background-position: -80px -564px;
}

.bt-editor-zoom-out:hover {
  background-position: -80px -602px;
}

.bt-editor-zoom-out:active {
  background-position: -80px -640px;
}

.bt-editor-zoom-in,
.bt-editor-zoom-in:disabled:hover {
  background-position: -42px -564px;
}

.bt-editor-zoom-in:hover {
  background-position: -42px -602px;
}

.bt-editor-zoom-in:active {
  background-position: -42px -640px;
}

.bt-editor-rotate {
  background-position: -2px -564px;
  width: 38px;
}

.bt-editor-rotate:hover {
  background-position: -2px -602px;
}

.bt-editor-rotate:active {
  background-position: -2px -640px;
}

.bt-editor-remove,
.bt-editor-remove-inactive {
  background-position: -118px -564px;
}

.bt-editor-remove:hover {
  background-position: -118px -602px;
}

.bt-editor-remove:active {
  background-position: -118px -640px;
}

.bt-overview-add,
.bt-overview-add-inactive {
  background-position: -162px -640px;
}

.bt-overview-add:hover {
  background-position: -200px -640px;
}

.bt-overview-add:active {
  background-position: -238px -640px;
}

.bt-overview-move-left,
.bt-overview-move-left-inactive {
  background-position: -276px -640px;
  transform: rotate(180deg);
}

.bt-overview-move-left:hover {
  background-position: -314px -640px;
  transform: rotate(180deg);
}

.bt-overview-move-left:active {
  background-position: -352px -640px;
  transform: rotate(180deg);
}

.bt-overview-move-right,
.bt-overview-move-right-inactive {
  background-position: -276px -640px;
}

.bt-overview-move-right:hover {
  background-position: -314px -640px;
}

.bt-overview-move-right:active {
  background-position: -352px -640px;
}

.icon-text-align-left,
.icon-text-align-left-active,
.icon-text-align-center,
.icon-text-align-center-active,
.icon-text-align-right,
.icon-text-align-right-active,
.icon-text-align-justify,
.icon-text-align-justify-active,
.icon-text-decoration-bold,
.icon-text-decoration-bold-active,
.icon-text-decoration-italic,
.icon-text-decoration-italic-active,
.icon-text-decoration-underline,
.icon-text-decoration-underline-active {
  width: 38px;
  height: 28px;
}

.icon-text-align-left-active,
.icon-text-align-left:hover,
.icon-text-align-center-active,
.icon-text-align-center:hover,
.icon-text-align-right-active,
.icon-text-align-right:hover,
.icon-text-align-justify-active,
.icon-text-align-justify:hover,
.icon-text-decoration-bold-active,
.icon-text-decoration-bold:hover,
.icon-text-decoration-italic-active,
.icon-text-decoration-italic:hover,
.icon-text-decoration-underline-active,
.icon-text-decoration-underline:hover {
  z-index: 1;
}

.icon-text-align-left {
  background-position: -162px -545px;
}

.icon-text-align-left:hover {
  background-position: -162px -575px;
}

.icon-text-align-left-active,
.icon-text-align-left:active {
  background-position: -162px -605px;
}

.icon-text-align-center {
  background-position: -202px -545px;
}

.icon-text-align-center:hover {
  background-position: -202px -575px;
}

.icon-text-align-center-active,
.icon-text-align-center:active {
  background-position: -202px -605px;
}

.icon-text-align-right {
  background-position: -242px -545px;
}

.icon-text-align-right:hover {
  background-position: -242px -575px;
}

.icon-text-align-right-active,
.icon-text-align-right:active {
  background-position: -242px -605px;
}

.icon-text-align-justify {
  background-position: -282px -545px;
}

.icon-text-align-justify:hover {
  background-position: -282px -575px;
}

.icon-text-align-justify-active,
.icon-text-align-justify:active {
  background-position: -282px -605px;
}

.icon-text-decoration-bold {
  background-position: -322px -545px;
}

.icon-text-decoration-bold:hover {
  background-position: -322px -575px;
}

.icon-text-decoration-bold-active,
.icon-text-decoration-bold:active {
  background-position: -322px -605px;
}

.icon-text-decoration-italic {
  background-position: -362px -545px;
}

.icon-text-decoration-italic:hover {
  background-position: -362px -575px;
}

.icon-text-decoration-italic-active,
.icon-text-decoration-italic:active {
  background-position: -362px -605px;
}

.icon-text-decoration-underline {
  background-position: -402px -545px;
}

.icon-text-decoration-underline:hover {
  background-position: -402px -575px;
}

.icon-text-decoration-underline-active,
.icon-text-decoration-underline:active {
  background-position: -402px -605px;
}

/* @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1),
  screen and (-o-min-device-pixel-ratio: 100/100),
  screen and (min-device-pixel-ratio: 1),
  screen and (min-resolution: 1dppx) {
  .bt-bottom-arrow-white,
  .bt-left-arrow-black,
  .bt-left-arrow-black-disabled,
  .bt-left-arrow-white-expand,
  .bt-left-arrow-white,
  .bt-left-double-arrow-white,
  .bt-menu-add-photo,
  .bt-menu-add-photo:hover,
  .bt-menu-add-photo-animated,
  .bt-menu-book,
  .bt-menu-book-pressed,
  .bt-menu-book:active,
  .bt-menu-book:hover,
  .bt-menu-selected,
  .bt-menu-selected-double,
  .bt-menu-clear-photo,
  .bt-menu-delete,
  .bt-menu-fill-photo,
  .bt-menu-group-photo,
  .bt-menu-photo,
  .bt-menu-photo-pressed,
  .bt-menu-photo:active,
  .bt-menu-photo:hover,
  .bt-menu-plus-one,
  .bt-menu-plus-one:active,
  .bt-menu-plus-one:hover,
  .bt-menu-remove-photo,
  .bt-menu-rotate,
  .bt-menu-rotate-disabled,
  .bt-menu-rotate:active,
  .bt-menu-rotate:hover,
  .bt-menu-shuffle-next,
  .bt-menu-shuffle-next:active,
  .bt-menu-shuffle-next:hover,
  .bt-menu-shuffle-previous,
  .bt-menu-shuffle-previous:active,
  .bt-menu-shuffle-previous:hover,
  .bt-menu-sort-photo,
  .bt-menu-style-manager,
  .bt-menu-style-manager-pressed,
  .bt-menu-style-manager:active,
  .bt-menu-style-manager:hover,
  .bt-menu-zoom-in,
  .bt-menu-zoom-in-disabled,
  .bt-menu-zoom-in:active,
  .bt-menu-zoom-in:hover,
  .bt-menu-zoom-out,
  .bt-menu-zoom-out-disabled,
  .bt-menu-zoom-out:active,
  .bt-menu-zoom-out:hover,
  .bt-redo-white,
  .bt-right-arrow-black,
  .bt-right-arrow-black-disabled,
  .bt-right-arrow-white,
  .bt-right-arrow-white-expand,
  .bt-right-arrow-white-expand-modal,
  .bt-right-double-arrow-white,
  .bt-top-arrow-white,
  .bt-undo-white,
  .bt-editor-close,
  .bt-editor-close-save,
  .bt-editor-remove,
  .bt-editor-remove-inactive,
  .bt-editor-zoom-in,
  .bt-editor-zoom-out,
  .bt-editor-rotate,
  .bt-overview-add,
  .bt-overview-add-inactive,
  .bt-overview-move-right-inactive,
  .bt-overview-move-right,
  .bt-overview-move-left-inactive,
  .bt-overview-move-left,
  .green-arrow-3d,
  .green-arrow-done,
  .green-arrow-pages,
  .icon-check,
  .icon-group-all-photos,
  .icon-group-all-photos-checked,
  .icon-group-manual-photos,
  .icon-group-manual-photos-checked,
  .icon-group-multi-used-photos,
  .icon-group-multi-used-photos-checked,
  .icon-group-not-used,
  .icon-group-not-used-checked,
  .icon-group-used-photos,
  .icon-group-used-photos-checked,
  .icon-sort-alfabetic,
  .icon-sort-alfabetic-checked,
  .icon-sort-date,
  .icon-sort-date-checked,
  .icon-text-align-left,
  .icon-text-align-left-active,
  .icon-text-align-center,
  .icon-text-align-center-active,
  .icon-text-align-right,
  .icon-text-align-right-active,
  .icon-text-align-justify,
  .icon-text-align-justify-active,
  .icon-text-decoration-bold,
  .icon-text-decoration-bold-active,
  .icon-text-decoration-italic,
  .icon-text-decoration-italic-active,
  .icon-text-decoration-underline,
  .icon-text-decoration-underline-active,
  .photo-bleed-cut,
  .photo-bleed-fill,
  .photo-padding-bg {
    background-image: url('atlas.png');
    -webkit-background-size: 444px 752px;
    -moz-background-size: 444px 752px;
    background-size: 444px 752px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2),
  screen and (-o-min-device-pixel-ratio: 200/100),
  screen and (min-device-pixel-ratio: 2),
  screen and (min-resolution: 2dppx) {
  .bt-bottom-arrow-white,
  .bt-left-arrow-black,
  .bt-left-arrow-black-disabled,
  .bt-left-arrow-white-expand,
  .bt-left-arrow-white,
  .bt-left-double-arrow-white,
  .bt-menu-add-photo,
  .bt-menu-add-photo:hover,
  .bt-menu-add-photo-animated,
  .bt-menu-book,
  .bt-menu-book-pressed,
  .bt-menu-book:active,
  .bt-menu-book:hover,
  .bt-menu-clear-photo,
  .bt-menu-delete,
  .bt-menu-fill-photo,
  .bt-menu-group-photo,
  .bt-menu-photo,
  .bt-menu-photo-pressed,
  .bt-menu-photo:active,
  .bt-menu-photo:hover,
  .bt-menu-plus-one,
  .bt-menu-plus-one:active,
  .bt-menu-plus-one:hover,
  .bt-menu-remove-photo,
  .bt-menu-rotate,
  .bt-menu-rotate-disabled,
  .bt-menu-rotate:active,
  .bt-menu-rotate:hover,
  .bt-menu-shuffle-next,
  .bt-menu-shuffle-next:active,
  .bt-menu-shuffle-next:hover,
  .bt-menu-shuffle-previous,
  .bt-menu-shuffle-previous:active,
  .bt-menu-shuffle-previous:hover,
  .bt-menu-sort-photo,
  .bt-menu-style-manager,
  .bt-menu-style-manager-pressed,
  .bt-menu-style-manager:active,
  .bt-menu-style-manager:hover,
  .bt-menu-zoom-in,
  .bt-menu-zoom-in-disabled,
  .bt-menu-zoom-in:active,
  .bt-menu-zoom-in:hover,
  .bt-menu-zoom-out,
  .bt-menu-zoom-out-disabled,
  .bt-menu-zoom-out:active,
  .bt-menu-zoom-out:hover,
  .bt-redo-white,
  .bt-right-arrow-black,
  .bt-right-arrow-black-disabled,
  .bt-right-arrow-white,
  .bt-right-arrow-white-expand,
  .bt-right-arrow-white-expand-modal,
  .bt-right-double-arrow-white,
  .bt-top-arrow-white,
  .bt-undo-white,
  .bt-editor-close,
  .bt-editor-close-save,
  .bt-editor-remove,
  .bt-editor-remove-inactive,
  .bt-editor-zoom-in,
  .bt-editor-zoom-out,
  .bt-editor-rotate,
  .bt-overview-add,
  .bt-overview-add-inactive,
  .bt-overview-move-right-inactive,
  .bt-overview-move-right,
  .bt-overview-move-left-inactive,
  .bt-overview-move-left,
  .green-arrow-3d,
  .green-arrow-done,
  .green-arrow-pages,
  .icon-check,
  .icon-group-all-photos,
  .icon-group-all-photos-checked,
  .icon-group-manual-photos,
  .icon-group-manual-photos-checked,
  .icon-group-multi-used-photos,
  .icon-group-multi-used-photos-checked,
  .icon-group-not-used,
  .icon-group-not-used-checked,
  .icon-group-used-photos,
  .icon-group-used-photos-checked,
  .icon-sort-alfabetic,
  .icon-sort-alfabetic-checked,
  .icon-sort-date,
  .icon-sort-date-checked,
  .icon-text-align-left,
  .icon-text-align-left-active,
  .icon-text-align-center,
  .icon-text-align-center-active,
  .icon-text-align-right,
  .icon-text-align-right-active,
  .icon-text-align-justify,
  .icon-text-align-justify-active,
  .icon-text-decoration-bold,
  .icon-text-decoration-bold-active,
  .icon-text-decoration-italic,
  .icon-text-decoration-italic-active,
  .icon-text-decoration-underline,
  .icon-text-decoration-underline-active,
  .photo-bleed-cut,
  .photo-bleed-fill,
  .photo-padding-bg {
    background-image: url('atlas@2x.png');
    -webkit-background-size: 444px 752px;
    -moz-background-size: 444px 752px;
    background-size: 444px 752px;
  }
} */
