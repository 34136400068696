.book-navigator-container,
.book-navigator {
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.book-navigator{
  position: relative;
}

.book-navigator-indexes {
  font-size: 14px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  opacity: 0.25;
}

.book-navigator-indexes.active {
  opacity: 1;
}

.overview-shadow {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
}

.book-navigator.overview:hover .overview-shadow,
.book-navigator.active .overview-shadow {
  background: rgba(0, 0, 0, 0);
}

.book-navigator.remove-active.overview:hover .overview-shadow {
  background: rgba(255, 152, 7, 0.5);
}

.book-navigator.active.remove-active .overview-shadow {
  background: rgba(255, 106, 4, 0.5);
}

.book-navigator.overview,
.book-navigator.active {
  cursor: pointer;
}

.book-navigator.overview:hover {
  box-shadow: 0px 0px 0px 1px #ff9807;
}

.book-navigator.overview:active,
.book-navigator.active {
  box-shadow: 0px 0px 0px 2px #ff6a04;
}

g .grabber {
  font-size: 14px;
  font-weight: bold;
  padding-right: 6px;
}

.print-marks {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
}

.print-marks .mark {
  width: 0.5mm;
  height: 2mm;
  background-color: black;
  left: 300mm;
  position: relative;
}

.scodix-marks {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.scodix-marks .scodix-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.scodix-marks .scodix-line .mark {
  background-color: black;
  width: 4mm;
  height: 4mm;
  border-radius: 100%;
  box-shadow: 0px 0px 0px 2mm white;
}
