.select {
  position: relative;
  display: inline-block;
  /* min-width: 81px; */
  /* min-height: 71px; */
  cursor: pointer;
}

.select * {
  cursor: pointer;
}

.select .cover {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  cursor: pointer;
}

.select .menu-layouts {
  font-size: 16px;
}

.select .menu-images {
  color: #a4a4a4;
  font-size: 14px;
}

.menu-images div {
  margin-bottom: 2px;
}

.select .menu-images:hover {
  color: #7c7c7c;
}

.select .menu-images:active {
  color: #ff6a04;
}

.select .layouts-cover {
  color: rgb(0, 169, 231);
}

.select .option-container {
  white-space: normal;
  position: absolute;
  background: #fff;
  margin: 0;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #a4a4a4;
  cursor: pointer;
  clear: both;
  z-index: 5;
}

.select .option-layouts {
  font-size: 16px;
}

.select .option-images {
  font-size: 14px;
}

.select .filter-img {
  min-width: 170px;
}

.select .layouts-text {
  min-width: 90px;
  margin-top: 3px;
}

.select .layouts-large-text {
  min-width: 100px;
  margin-top: 3px;
}

.select .layouts-applyMethod {
  margin-top: 18px;
  min-width: 280px;
}

.images:hover .option-container {
  border: 1px solid #7c7c7c;
}

.menu {
  border: 1px solid #7c7c7c;
}

.select .option {
  padding: 5px;
}

.select .option input {
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: absolute;
  opacity: 0;
}

.select .option .label-checked,
.select .option:active label {
  color: #ff6a04;
}

.select .option:hover {
  color: #7c7c7c;
  background-color: #d7d7d7;
}

.select .option label {
  position: relative;
  display: block;
}

.select .option label span {
  margin: 0 8px;
  line-height: 30px;
  vertical-align: top;
  cursor: pointer;
}

.select .option label div,
.select .option label span {
  display: inline-block;
}

.select .option {
  display: none;
}

.menu-images {
  margin-bottom: 7px;
}

.images:hover .option {
  display: inline-block;
  width: 100%;
}

.menu .option {
  display: inline-block;
  width: 100%;
}

.select .option input:focus + label {
  display: block;
  /* outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px; */
}

.select .option-container .checked {
  position: absolute;
  top: 50%;
  right: 0;
  margin: -7px 0 0;
}

.cover button {
  padding: 0;
  border-style: solid;
  width: 0;
  height: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-color: initial;
  border-radius: 0;
}

.cover button.decrease,
.cover:hover button.decrease:disabled {
  border-width: 8px 5px 0;
  border-color: #00a9e6 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.cover:hover button.decrease {
  border-color: #ff9807 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.cover:active button.decrease {
  border-color: #ff6a04 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.cover button:focus {
  outline: 0;
}
