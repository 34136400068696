.photoEditorLayer {
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  z-index: 1;
}

.visible {
  display: block;
}

.shape-barcode {
  width: 346px;
  float: right;
}

.rich-text .__react_component_tooltip.type-dark {
  /* color: #fff; */
  background-color: red !important;
}

.cursor-help {
  cursor: help;
}

.text-box {
  width: 100%;
  height: 100%;
  border-color: #ccc;
  background-color: transparent;
  border-style: solid;
  outline: none;
  resize: none;
  text-decoration-skip-ink: none;
  text-justify: inter-character;
}

.text-box.text-blink {
  animation: blink 1s infinite;
}

.text-box.have-content {
  border-color: transparent;
}

.text-box:hover {
  border-color: #868686;
}

.text-box.active,
.text-box:active {
  border-color: #ff9807;
  overflow: hidden;
}

.text-box:active::placeholder {
  color: transparent;
}

.text-box:disabled {
  background: #fff;
}

.text-box:not(:focus):before {
  content: attr(data-text);
  color: #aeaeae;
}

.text-box:hover:not(:focus):before {
  color: #7c7c7c;
}

.text-box-white {
  width: 100%;
  height: 100%;
  border-color: #FFFFFF;
  background-color: transparent;
  border-style: solid;
  outline: none;
  resize: none;
  text-decoration-skip-ink: none;
  text-justify: inter-character;
}

.text-box-white.text-blink {
  animation: blink 1s infinite;
}

.text-box-white.have-content {
  border-color: transparent;
}

.text-box-white:hover {
  border-color: #868686;
}

.text-box-white.active,
.text-box-white:active {
  border-color: #ff9807;
}

.text-box-white:active::placeholder {
  color: transparent;
}

.text-box-white:disabled {
  background: #fff;
}

.text-box-white:not(:focus):before {
  content: attr(data-text);
  color: #F5F5F5;
}

.text-box-white:hover:not(:focus):before {
  color: #7c7c7c;
}