.header {
  width: 100%;
  min-height: 64px;
  background-color: #fff;
  box-shadow: 0 1px 7px 0 #888;
  z-index: 5;
  padding: 0 1.5%;
}

.header .content {
  padding: 7px 0;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
}
@media (min-width: 1024px) {
  .header .switch-container .arrow-3d {
    display: inline-block !important;
  }
}
@media (min-width: 576px) {
  .header .form {
    min-width: initial !important;
  }

  .header input.done {
    width: 126px !important;
    flex: 1 0 0;
  }

  .header .info .arrow-pages {
    display: inline-block !important;
  }

  .header .arrow-done {
    left: auto !important;
    transform: translate3D(0, 0, 1px) !important;
    right: 0 !important;
  }

  .header .form {
    margin: 0 !important;
  }
}

.header .logo {
  align-self: center;
}

.header .info {
  padding: 0 1.5%;
  color: #a4a4a4;
  font-size: 14px;
  flex: 1 0 0;
  align-self: center;
}

.header .info div {
  display: inline-block;
}

.header .form {
  min-width: 100%;
  position: relative;
  align-self: center;
  margin: 5px 0 0;
}

.header .form form {
  display: flex;
  flex-flow: row nowrap;
}

.form form .button {
  margin: 0;
}

.header .info .headline {
  display: block;
}

.header .info h1 {
  font-size: 16px;
  font-weight: 700;
  color: #868686;
  margin: 0 0 3px;
  display: inline-block;
}

.header input.done,
.header input.done:disabled:hover {
  background-color: #00a9e7;
  color: #fff;
  line-height: 1.42857143;
  font-size: 24px;
  font-weight: 700;
  border: none;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  cursor: pointer;
  border-radius: 0;
  flex: 1 0 0;
}

.header input.done:hover {
  background-color: #ff9807;
}

.header input.done:active {
  background-color: #ff6a04;
}

.header input.done:disabled {
  opacity: 0.25;
}

.header input.done:disabled:hover {
  cursor: default;
}

.header input.faq {
  width: 42px;
  background-color: #aeaeae;
  border: none;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.42857143;
  margin: 2px;
  cursor: pointer;
  border-radius: 0;
  text-align: center;
}

.header input.faq:hover {
  background-color: #868686;
}

.header input.faq:active {
  background-color: #ff6a04;
}

@-webkit-keyframes buy-arrow-loop {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: 20px;
  }

  100% {
    margin-top: 0;
  }
}
@keyframes buy-arrow-loop {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: 20px;
  }

  100% {
    margin-top: 0;
  }
}

.buy-arrow-loop {
  -webkit-animation: buy-arrow-loop 1.25s ease-in-out infinite;
  animation: buy-arrow-loop 1.25s ease-in-out infinite;
}

.header .arrow-done {
  transform: translate3D(0, 0, 0);
  width: 126px;
  position: absolute;
  left: 50%;
  transform: translate(-34px, 0);
  top: 45px;
  font-family: Edo-Regular, lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #00b22d;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
}

.header .arrow-done .green-arrow-done {
  display: inline-block;
}

.header .page-info {
  position: relative;
}

.header .info .arrow-pages {
  width: 167px;
  position: absolute;
  top: 20px;
  left: -45px;
  font-family: Edo-Regular, lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #00b22d;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
  display: none;
}

.header .arrow-done .green-arrow-done {
  display: inline-block;
}

.header .switch-container {
  position: relative;
}

.header .switch-container .arrow-3d {
  display: none;
  position: absolute;
  top: 20px;
  left: -10px;
  font-family: Edo-Regular, lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #00b22d;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
  min-width: 172px;
}

.header .custom-product{
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}

.bt-config-container{
  display: flex;
  flex-direction: column;
}
