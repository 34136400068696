.ModalCollar {
  z-index: 777 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalCollar > .container {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 750px;
  height: 500px;
  background: #fff;
  display: inline-block;
}

.ModalCollar > .container >.imagens{
  position: relative;
  height: 300px;
}

.ModalCollar > .container > .imagens > button {
  position: absolute;
  margin-top: 114px;
}

.ModalCollar > .container > .textos {
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 0 75px 0 75px;
}

.ModalCollar > .container > .textos > .titulo {
  display: block;
  font-weight: bold;
  padding: 23px 0 0 0;
  font-size: 22px;
  color: #868686;
}

.ModalCollar > .container > .textos > .message {
  font-size: 20px;
  margin-top: -1px;
  color: #a4a4a4;
}

.ModalCollar > .container > .textos > button {
  margin: 15px;
  margin-top:20px;
  padding-top: 1px;
  width: 200px;
  height: 36px;
  font-weight: 'normal';
  font-size: 22px;
}

.ModalCollar > .container > .textos > .tamanhoColeira{
  margin-top: 6px;
  font-size: 20px;
}
