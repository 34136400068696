.nav-layouts {
  padding: 12px 0;
  display: flex;
  font-size: 16px;
  color: rgba(164, 164, 164);
}

.filter-layouts {
  white-space: nowrap;
}

.layouts-box-container {
  flex-grow: 1;
}

.line-divisor {
  background: #d7d7d7;
  min-width: 1px;
  height: 52px;
}

.nav-layouts .title {
  margin: 0 0 7px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(134, 134, 134);
}

.nav-layouts .line:last-child {
  margin-bottom: 3px;
}

.nav-layouts .line .filter-item {
  color: rgb(0, 169, 231);
  display: inline-block;
}
