#photoEditor {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  z-index: 5;
}

#photoEditor button {
  margin: 0;
}

.editor-options-container {
  display: flex;
  font-size: 14px;
  color: white;
}

.editor-options-container {
  padding-top: 30px;
}

.editor-options:not(:last-child) {
  margin-right: 18px;
}

.editor-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editor-options span {
  margin-top: 3px;
}
