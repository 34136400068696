.numeric-stepper .stepper-container button {
  padding: 0;
  border-style: solid;
  width: 0;
  height: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-color: initial;
  border-radius: 0;
}

.numeric-stepper .stepper-container.vertical {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 50px;
  left: calc(100% - 19px);
  z-index: 0;
  width: 10px;
}

.numeric-stepper .number-value {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.numeric-stepper button.decrease {
  border-width: 8px 5px 0;
  border-color: #00a9e6 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.numeric-stepper button.decrease:hover {
  border-color: #ff9807 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.numeric-stepper button.decrease:active {
  border-color: #ff6a04 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
}

.numeric-stepper button.increase {
  border-width: 0 5px 8px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #00a9e6;
}

.numeric-stepper button.increase:hover {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ff9807;
}

.numeric-stepper button.increase:active {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ff6a04;
}

.numeric-stepper button.decrease.disabled {
  border-color: #00a9e644 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  cursor: auto;
}

.numeric-stepper button.increase.disabled {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #00a9e644;
  cursor: auto;
}

.numeric-stepper button:focus {
  outline: 0;
}
