.product {
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: column;
  position: absolute;
  z-index: 0;
  transition: background 0.08s ease;
  background-position: 50% 0%;
  overflow: hidden;
}

@media print {
  .product {
    background: transparent;
  }
}

.product .product-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background: #333;
}

.product .product-list li {
  display: inline-block;
  margin: 5px 5px;
}

.product .product-list li a {
  color: #fefefe;
  text-decoration: none;
}
body.ReactModal__Body--open {
  z-index: 999 !important;
  position: relative !important;
}