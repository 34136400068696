.container-select-format{
  display: grid;
  grid-template-columns: 23px 1fr 22px;
  align-self: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.container-select-format > .left-button{
  display: flex;
  align-items: center;
  align-content: center;
}

.container-select-format > .right-button{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: center;
}

.container-select-format > .select-format {
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  grid-gap: 14px;
  height: 100%;
  padding: 2px 2px 2px 2px;
}

.container-select-format > .select-format > .item-rectangle {
  cursor: pointer;
  width: 146px;
  height: 106px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  outline: 1px solid rgb(204,204,204);
}

.container-select-format > .select-format > .item-rectangle > .format, .size {
  font-size: 20px;
  font-family: "Lato";
  color: rgb( 134, 134, 134 );
  font-weight: bold;
}

.container-select-format > .select-format > .item-rectangle > .size {
  margin-top: -3px;
}

.container-select-format > .select-format > .item-rectangle > .price {
  padding-top: 6px;
  font-size: 20px;
  font-family: "Lato";
  color: rgb( 164, 164, 164 );
}

.container-select-format > .select-format > .item-rectangle:hover{
  outline: 1px solid rgb(134,134,134);
}

.container-select-format > .select-format > .item-rectangle.active{
  outline: 2px solid rgb(255,106,4);
}