.menu-book {
  width: 100%;
  z-index: 5;
}

.menu-book.expanded {
  width: 100%;
  flex-direction: column;
  margin: 0;
  justify-content: flex-start;
}

.menu-book .content {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 130px 1fr 30px;
  padding-bottom: 1px;
}

.menu-book button,
.menu-book button:active,
.menu-book button:hover {
  margin: 0;
}

.menu-book .content .menu-container {
  text-align: center;
}

.menu-container-outside {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-container {
  display: flex;
  align-items: center;
}

.menu-book .bt-container {
  display: inline-block;
  vertical-align: top;
}
.bt-menu-book-container div {
  max-height: 41px;
}

.page-navigator {
  display: flex;
  align-content: center;
}

.page-navigator.reverse {
  flex-direction: row-reverse;
}

.btn-label-container {
  min-width: 275px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1px;
}

.btn-label-container .label {
  color: #fff;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 22px;
}

.btn-label-container .label-modo-visualizacao {
  color: #fff;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 22px;
}

.btn-label-container .label-modo-visualizacao:hover {
  color: #FF9807;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 22px;
}

.page-btns {
  display: flex;
  padding-bottom: 2px;
}

.page-btns button {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}

.padd-num {
  display: flex;
  align-items: center;
  height: 50px;
}

.arrow-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 9px;
}

.padd-num.left {
  background-color: red;
}

.bt-container.bt-container-overview {
  display: flex;
}
