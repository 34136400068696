.popup-manager {
  z-index: 777 !important;
}

.popup-manager.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
  top: 0;
}

.popup-manager > .container {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 496px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 24px;
  background: #fff;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.popup-manager > .container.not-fixed-width {
  width: auto;
  max-width: 346px;
  min-width: 280px;
}

.popup-manager > .container.not-fixed-width > .popup-default-styles > h3,
.popup-manager
  > .container.not-fixed-width
  > .popup-default-styles
  > .message
  > p {
  text-align: center;
}

.popup-manager > .container.not-fixed-width > .popup-default-styles > .message {
  margin: 12px 0 0 0;
}

.popup-manager > .container > .popup-default-styles .title {
  min-width: 450px;
  color: #666;
  text-align: center;
  font-size: 38px;
  margin: 30px 0 0;
}

.popup-manager > .container > .popup-default-styles > h3 {
  font-size: 22px;
  color: #868686;
  margin: 0;
}

.popup-manager > .container > .popup-default-styles > .message {
  margin: 25px 0 31px;
  font-size: 20px;
  color: #a4a4a4;
}

.popup-manager > .container > .popup-default-styles > .message > p {
  margin: 0;
}

.popup-manager > .container > .popup-default-styles > .input-field {
  margin-top: -10px;
  margin-bottom: 30px;
}

.popup-manager
  > .container
  > .popup-default-styles
  > .input-field
  > .input-error {
  color: #eb0000;
  font-size: 20px;
}

.popup-manager > .container > .popup-default-styles > .input-field > input {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #cccccc;
  outline: none;
  color: #868686;
  font-size: 20px;
  padding: 12px 20px;
}

.popup-manager
  > .container
  > .popup-default-styles
  > .input-field
  > input[data-error='true'] {
  margin-top: 12px;
  border-color: #eb0000;
}

.popup-manager
  > .container
  > .popup-default-styles
  > .input-field
  > input[data-warning='true'] {
  margin-top: 12px;
}

.popup-manager
  > .container
  > .popup-default-styles
  > .input-field
  > input::placeholder {
  color: #a4a4a4;
}

.popup-manager > .container > .popup-default-styles > .buttons {
  display: flex;
  justify-content: space-between;
}

.popup-manager > .container > .popup-default-styles > .buttons > button {
  height: 36px;
}

.input-file {
  display: none;
}

.label-add-photo-popup {
  line-height: 1.42857143;
  border: none;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  cursor: pointer;
  border-radius: 0;
  outline: 0;
  color: #fff;
  background-color: #ff9807;
  width: 100%;
  padding: 0 10px 4px;
  margin: 0;
  margin-left: 10px;
  font-weight: normal;
  font-size: 22px;
  text-align: center;
}

.label-add-photo-popup:hover {
  background-color: #ff6a04;
}

.submiting {
  text-align: center;
}

.submiting h3 {
  font-size: 48px;
  color: #868686;
  margin: 16px 0 6px;
}

.submiting h4 {
  font-size: 22px;
  color: #868686;
  margin: 0 0 6px 0;
}

.submiting p {
  font-size: 20px;
  color: #a4a4a4;
  margin: 0;
}
