.thumb {
  cursor: move;
  position: relative;
  min-width: auto;
  min-height: auto;
  vertical-align: middle;
}

.thumb .image-container {
  display: flex;
}

.thumb .image-container > img {
  transform-origin: center;
}

.thumb .image-container > img {
  border: 1px solid #fff;
  z-index: 0;
}

.thumb .image-container.canDrop {
  opacity: 0.5;
}

.thumb .image-container.isDragging {
  opacity: 1;
}

.thumb .image-container.isDragging > img,
.thumb:hover .image-container:not(.canDrop) > img {
  background: #ffffff25;
}

.thumb .image-container.pair > img {
  transform: rotate(5deg);
}

.thumb .image-container.odd > img {
  transform: rotate(-5deg);
}

.thumb .image-container:before {
  content: '';
  display: block;
  width: auto;
  height: auto;
  transition: ease-in 250ms ease-out 100ms;
}

.thumb .image-container.manual-sort.isOver:not(.isDragging):before {
  width: auto;
}

.thumb .image-container .remove-btn {
  display: none;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
}

.thumb .image-container .selected-btn {
  position: absolute;
  z-index: 2;
}

.thumb .image-container:hover .remove-btn {
  display: block;
}

.thumb .image-container:active .remove-btn {
  display: none;
}

.thumb .image-container:active .remove-btn:active {
  display: block;
}

.thumb .image-container.isDragging:hover .remove-btn {
  display: none;
}

.stack {
  z-index: -1;
  position: absolute;
  top: 6px;
  border: 1px solid #fff;
}

.stack.odd {
  transform: rotate(5deg);
}

.stack.pair {
  transform: rotate(-5deg);
}

.displayNone {
  display: none;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-quantidade-utilizada{
  position: absolute;
  background-image: url('../../asset/icons/contador_fotos.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
  z-index: 2;
}

.quantidade-utilizada{
  position: absolute;
  margin-top: -0.5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#ffffff;
  font-family: lato;
}
