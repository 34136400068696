* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #4f4337;
  background: url('asset/bg-mesa.webp');
  overflow-x: hidden;
  user-select: none;
}

.menu-photo {
  z-index: 8;
  display: none;
}

.photo-display:hover + .menu-photo {
  display: block !important;
}

.photo-mask:active + .menu-photo {
  display: none !important;
}

.menu-photo:hover,
.menu-photo:hover + .overview-item-menu {
  display: block !important;
}

/* Edo Regular */
@font-face {
  font-family: 'Edo-Regular';
  src: local('☺'), url('asset/font/edo-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Abril Fatface */
@font-face {
  font-family: 'Abril Fatface';
  src: url('asset/font/Abril-FatFace/AbrilFatface-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

/* Arvo */
@font-face {
  font-family: 'Arvo';
  src: url('asset/font/Arvo/Arvo-Regular_201.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Arvo';
  src: url('asset/font/Arvo/Arvo-Bold_201.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Arvo';
  src: url('asset/font/Arvo/Arvo-Italic_201.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Arvo';
  src: url('asset/font/Arvo/Arvo-BoldItalic_201.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Blokletters */
@font-face {
  font-family: 'Blokletters Balpen';
  src: url('asset/font/Blokletters/Blokletters-Balpen.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Blokletters';
  src: url('asset/font/Blokletters/Blokletters-Viltstift.ttf');
  font-weight: bold;
  font-style: normal;
}

/* Cookie */
@font-face {
  font-family: 'Cookie';
  src: url('asset/font/Cookie/Cookie-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

/* Gandhi Sans */
@font-face {
  font-family: 'Gandhi Sans';
  src: url('asset/font/Gandhi-sans/GandhiSans-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gandhi Sans';
  src: url('asset/font/Gandhi-sans/GandhiSans-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Gandhi Sans';
  src: url('asset/font/Gandhi-sans/GandhiSans-Italic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Gandhi Sans';
  src: url('asset/font/Gandhi-sans/GandhiSans-BoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}

/* GoodDog */
@font-face {
  font-family: 'GoodDog';
  src: url('asset/font/GoodDog/GoodDog.otf');
  font-weight: normal;
  font-style: normal;
}

/* Grand Hotel */
@font-face {
  font-family: 'Grand Hotel';
  src: url('asset/font/Grand-Hotel/GrandHotel-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

/* Great Vibes */
@font-face {
  font-family: 'Great Vibes';
  src: url('asset/font/Great-Vibes/GreatVibes-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

/* Josefin Sans */
@font-face {
  font-family: 'Josefin Sans';
  src: url('asset/font/Josefin-Sans/JosefinSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Josefin Sans';
  src: url('asset/font/Josefin-Sans/JosefinSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Josefin Sans';
  src: url('asset/font/Josefin-Sans/JosefinSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Josefin Sans';
  src: url('asset/font/Josefin-Sans/JosefinSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Komika Axis */
@font-face {
  font-family: 'Komika Axis';
  src: url('asset/font/Komika-Axis/KOMIKAX_.ttf');
  font-weight: normal;
  font-style: normal;
}

/* Liberation Sans */
@font-face {
  font-family: 'Liberation Sans';
  src: url('asset/font/Liberation-Sans/LiberationSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('asset/font/Liberation-Sans/LiberationSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('asset/font/Liberation-Sans/LiberationSans-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Sans';
  src: url('asset/font/Liberation-Sans/LiberationSans-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Liberation Serif */
@font-face {
  font-family: 'Liberation Serif';
  src: url('asset/font/Liberation-Serif/LiberationSerif-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Serif';
  src: url('asset/font/Liberation-Serif/LiberationSerif-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Liberation Serif';
  src: url('asset/font/Liberation-Serif/LiberationSerif-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Liberation Serif';
  src: url('asset/font/Liberation-Serif/LiberationSerif-BoldItalic.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Lobster Two */
@font-face {
  font-family: 'Lobster Two';
  src: url('asset/font/Lobster-Two/LobsterTwo-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lobster Two';
  src: url('asset/font/Lobster-Two/LobsterTwo-Bold.otf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lobster Two';
  src: url('asset/font/Lobster-Two/LobsterTwo-Italic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Lobster Two';
  src: url('asset/font/Lobster-Two/LobsterTwo-BoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}

/* Walkway */
@font-face {
  font-family: 'Walkway';
  src: url('asset/font/Walkway/Walkway_SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Walkway';
  src: url('asset/font/Walkway/Walkway_UltraBold.ttf');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Walkway';
  src: url('asset/font/Walkway/Walkway_Oblique_SemiBold.ttf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Walkway';
  src: url('asset/font/Walkway/Walkway_Oblique_UltraBold.ttf');
  font-weight: bold;
  font-style: italic;
}

/* Amatic SC */
@font-face {
  font-family: 'Amatic SC';
  src: url('asset/font/Amatic-SC/AmaticSC-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

/* PlayfairDisplay */
@font-face {
  font-family: 'PlayFair Display';
  src: url('asset/font/PlayfairDisplay/PlayfairDisplay-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat Black';
  src: url('asset/font/Montserrat/Montserrat-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Bold';
  src: url('asset/font/Montserrat/Montserrat-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

/* JulliaScript */
@font-face {
  font-family: 'JulliaScript';
  src: url('asset/font/JulliaScript/JulliaScript-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@media not all, (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    background: url('asset/bg-mesa.webp');
    background-size: 1013px 675px;
  }
}
@media print {
  body {
    background: transparent;
  }
  header,
  .nav-manager,
  .menu-book {
    display: none;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
