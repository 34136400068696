.btn-close-modal{
  z-index: 778 !important;
  position: absolute;
  top: 13px;
  right: 13px;
}

.modal-config {
  z-index: 777 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-config > .container {
  position: absolute;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 750px;
  height: 500px;
  background: #fff;
  display: inline-block;
}

.modal-config > .container > .options {
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 0 37px 0 37px;
}

.modal-config > .container > .options > .title {
  display: block;
  font-weight: bold;
  padding: 25px 0 0 0;
  font-size: 22px;
  color: rgb( 134, 134, 134 );
}

.modal-config > .container > .options > .subtitle {
  display: block;
  font-size: 20px;
  margin-top: -1px;
  color: rgb( 164, 164, 164 );
  margin-bottom: 20px;
}

.modal-config > .container > .options > .select-option{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 6px;
  margin-bottom: 2px;
  font-size: 20px;
}

.modal-config > .container > .options > .select-option > .item-option {
  outline: 1px solid rgb(204,204,204);
  width: 608px;
  height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.modal-config > .container > .options > .select-option > .item-option:hover{
  outline: 1px solid rgb(134,134,134);
}

.modal-config > .container > .options > .select-option > .item-option.active{
  outline: 2px solid rgb(255,106,4);
}

.modal-config > .container > .options > .select-option > .item-option > .title {
  font-size: 20px;
  font-weight: bold;
  color: rgb( 134, 134, 134 );
}

.modal-config > .container > .options > .select-option > .item-option > .subtitle {
  font-size: 20px;
  margin-top: -1px;
  color: rgb( 164, 164, 164 );
}

.modal-config > .container > .options > button {
  margin: 15px;
  margin-top: 20px;
  padding-top: 1px;
  width: 200px;
  height: 36px;
  font-weight: 'normal';
  font-size: 22px;
}
