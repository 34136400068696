@keyframes warn-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes warn-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.warning-container {
  position: absolute;
  top: 0;
  right: calc(50% - 268px);
  z-index: 5;
  padding: 20px;
}

.warning-container.warning-enter {
  animation-name: warn-in;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.warning-container.warning-exit {
  animation-name: warn-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.warning-container img {
  max-width: 34px;
  max-height: 34px;
  position: relative;
  left: calc(50% - 17px);
  top: 12.75px;
  border-radius: 50%;
  box-shadow: 0px 3px 9px rgb(0, 0, 0);
}

.warning-container .warning-text {
  max-width: 496px;
  text-align: justify;
  background-color: rgb(235, 0, 0);
  color: white;
  padding: 16px;
  font-size: 14px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
  border: 2px solid white;
}
