img.fechar {
  width: 80%;
}

.video-react {
  padding-top: 0 !important;
  position: initial !important;
}

button {
  font: 400 13.3333px Arial;
}

.playModal {
  z-index: 5;
  position: absolute;
  width: 90px;
  height: 45px;
  right: calc(50% - 45px);
  top: calc(50% - 22px);
  cursor: pointer;
  border: 0.06em solid #fff;
  border-radius: 0.3em;
  font-size: 1.75em;
  line-height: 1.25em;
  color: #fff;
  background-color: #000000;
  opacity: 0.5;
  outline: none;
}

.playModal:hover {
  opacity: 0.75;
}

.playModal::before {
  font-size: 23px;
  content: '\25B6';
}

.closeModal {
  z-index: 5;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  border: 0.06em solid #fff;
  border-radius: 0.3em;
  font-size: 1.75em;
  line-height: 1.25em;
  height: 1.5em;
  width: 3em;
  color: #fff;
  transition: all 0.4s;
  padding: 0;
  outline: none;
  background-color: #EB0000;
  opacity: 0.75;
}

.closeModal:hover {
  background-color: #d70000;
  transition: all 0s;
}

.closeModal::before {
  content: '\2716';
}

.ModalMesa1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.98);
}

.ModalMask {
  z-index: 777 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalMask > .container {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 750px;
  height: 500px;
  background: #fff;
  display: inline-block;
}

.ModalMask > .container >.imagens{
  position: relative;
  height: 300px;
}

.ModalMask > .container > .imagens > button {
  position: absolute;
  margin-top: 114px;
}

.ModalMask > .container > .textos {
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 0 71px 0 71px;
}

.ModalMask > .container > .textos > .titulo {
  display: block;
  font-weight: bold;
  padding: 23px 0 0 0;
  font-size: 22px;
  color: #868686;
}

.ModalMask > .container > .textos > .message {
  font-size: 20px;
  margin-top: -1px;
  color: #a4a4a4;
}

.ModalMask > .container > .textos > button {
  margin: 15px;
  margin-top:18px;
  padding-top: 1px;
  width: 200px;
  height: 36px;
  font-weight: 'normal';
  font-size: 22px;
}
