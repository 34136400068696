.container-tamanho-coleira{
  display: grid;
  grid-template-columns: 27px 1fr 27px;
  align-self: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.container-tamanho-coleira > .left-button{
  display: flex;
  align-items: center;
  align-content: center;
}

.container-tamanho-coleira > .right-button{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  align-content: center;
}

.container-tamanho-coleira > .tamanho-coleiras {
  display: inline-flex;
  align-self: center;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  grid-gap: 0 6px;
}

.container-tamanho-coleira > .tamanho-coleiras > .item-rectangle {
  height: 38px;
}

.container-tamanho-coleira > .tamanho-coleiras > .item-rectangle > .size {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  cursor: pointer;
  background-color: #AEAEAE;
  color: #FFFFFF;
}

.container-tamanho-coleira > .tamanho-coleiras > .item-rectangle :hover{
  background-color: #868686;
}

.container-tamanho-coleira > .tamanho-coleiras > .item-rectangle > .active{
  background-color: #FF6A04;
}

.container-tamanho-coleira > .tamanho-coleiras > .item-rectangle :active{
  background-color: #FF6A04;
}