.nav-manager {
  position: relative;
  z-index: 4;
}

.nav-manager > .bar.overlay {
  position: absolute;
}

.nav-manager > .bar.active {
  transform: translate(0, 0);
}

.nav-manager > .bar {
  background: #fff;
  width: 100%;
  transition: transform 250ms;
  transform: translate(0, -100%);
}

.nav-manager > .bar > .container {
  width: 100%;
  display: block;
  padding: 0 1.5%;
  max-width: 100%;
}
