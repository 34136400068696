.legenda {
  /* z-index: 6!important; */
  z-index: 999!important;
  position: fixed;
  top: 50%;
  height: fit-content;
  background: #fff;
  font-family: lato;
  font-size: 16px;
  font-weight: bold;
  color: #868686;
  right: -380px;
  box-shadow: 0 1px 7px 0 #888;
}

.legenda:after {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid white;
  content: "";
  right: -12px;
  top: calc(50% - 12px);
  position: absolute;
}

.legenda .header-text {
  height: 36px;
  background: #2D2D2D;
  display: flex;
  align-items: center;
  padding: 0;
}

.legenda > .header-text > .text {
  color: white;
  margin-top: -1px;
  margin-left: 14px;
}

.legenda > .content {
  padding: 12px 0 4px 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.legenda > .content > .content-line{
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;
}

.legenda > .content > .content-line .button-text{
  font-weight: normal;
  margin-top: -2px;
  margin-left: 7px;
}

.legenda > .content > .content-line .content-line-img{
  margin-right: 6px;
}

.legenda > .content > .content-line .button-plus-separator{
  width: 12px;
  margin-top: -2px;
  margin-left: -2.355px;
}

.legenda > .content > .content-line .button-slash-separator{
  margin-top: -2px;
  margin-left: -1.1px;
  width: 11px;
}

.animacaoEntrada{
  transition: right 500ms cubic-bezier(0.265, 0.365, 0.26, 0.865);
  right: 13px;
}

.animacaoSaida{
  transition: right 500ms cubic-bezier(0.265, 0.365, 0.26, 0.865);
  right: -420px;
}
