.mockup {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  overflow: auto;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mockup img {
  display: block;
  max-width: 100%;
  height: auto;
  padding: 10px;
}
