.BibliotecaTemas {
  z-index: 999 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: lato;
}

.BibliotecaTemas > .popup {
  font-family: lato;
  width: 85%;
  height: 85%;
  background: #fff;
  display: grid;
  grid-template-columns: 196px 1fr;
  grid-template-rows: 60px 1fr 100px;
  grid-template-areas:
		"header-text header-content"
		"side-menu main-container"
		"footer footer";
}

.BibliotecaTemas > .popup > .header-text {
  background: #2d2d2d;
  display: flex;
  align-items: center;
}

.BibliotecaTemas > .popup > .header-content {
  padding: 0 19px 0 21px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 6px;
  border-bottom: 1px solid rgb(215,215,215);
}

.BibliotecaTemas > .popup > .header-content > .header-button {
  display: flex;
  flex-direction: row-reverse;
}

.BibliotecaTemas > .popup > .header-content > .header-category {
  display: inline-flex;
  font-family: lato;
  font-size: 14px;
  color: rgb(164,164,164);
}
.BibliotecaTemas > .popup > .header-content > .header-category > .text-interactive {
  font-weight: 400;
  cursor: pointer;
}

.BibliotecaTemas > .popup > .header-content > .header-category > .text-interactive:hover {
  color: rgb(255,152,7);
}

.BibliotecaTemas > .popup > .header-content > .header-category > .text-interactive:active {
  color: rgb(255,106,4);
}

.BibliotecaTemas > .popup > .header-content > .header-desselecionar {
  display: flex;
  flex-direction: row-reverse;
  color: rgb(235,0,0);
}

.BibliotecaTemas > .popup > .header-content > .header-desselecionar:hover {
  color: rgb(215,0,0);
}

.BibliotecaTemas > .popup > .header-content > .header-desselecionar:active {
  color: rgb(200,0,0);
}

.BibliotecaTemas > .popup > .header-content > .header-selecionar {
  display: inline-flex;
}

.BibliotecaTemas > .popup > .header-content > .header-selecionar > .header-selecionar-todas {
  cursor: pointer;
  color: #00B22D;
  font-family: lato;
  font-size: 14px;
  padding-left: 4px;
  align-self: center;
  font-weight: 400;
}

.BibliotecaTemas > .popup > .header-content > .header-selecionar > .header-selecionar-todas:hover{
  color: #009218
}

.BibliotecaTemas > .popup > .header-content > .header-selecionar> .header-selecionar-todas:active{
  color: #006904;
}

.BibliotecaTemas > .popup > .header-text  > div {
  font-family: lato;
  font-weight: bold;
  font-size: 22px;
  margin-left: 20px;
  color: white;
}

.BibliotecaTemas > .popup > .side-menu{
  border-right: 1px solid rgb(215,215,215);
}

.BibliotecaTemas > .popup > .side-menu > .item-menu{
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 0px 9px 20px;
  font-size: 18px;
  color: rgb(134, 134, 134);
  background-color: rgb(255,255,255);
}

.BibliotecaTemas > .popup > .side-menu > .item-menu:hover{
  color: rgb(255,255,255);
  background-color: rgb(255,152,7);
}

.BibliotecaTemas > .popup > .side-menu > .item-menu:active{
  color: rgb(255,255,255);
  background-color: rgb(255,106,4);
}

.BibliotecaTemas > .popup > .side-menu > .item-menu-active{
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 9px 0px 9px 20px;
  font-size: 18px;
  color: rgb(255,255,255);
  background-color: rgb(255,106,4);
}

.BibliotecaTemas > .popup > .main-container{
 padding: 20px 22px 0 22px;
 display: grid;
 grid-gap: 2.75vh 2.35vw;
 grid-template-columns: repeat(auto-fit, minmax(138px, 138px));
 justify-content: space-between;
 grid-auto-rows: 186px;
 overflow-y: auto;
}

.BibliotecaTemas > .popup > .main-container > .item-card{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
  padding-top: 1px;
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-rectangle{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 138px;
  outline: 1px solid rgb(204,204,204);
  outline-offset: -1px;
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-rectangle:hover{
  outline: 1px solid rgb(134,134,134);
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-rectangle:active{
  outline: 2px solid rgb(255,106,4);
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-rectangle .item-img{
  background: white;
  width: 136px;
  height: 136px;
  opacity: 1;
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-rectangle .item-img:hover{
  opacity: 0.75;
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-card-title{
  padding-top: 6px;
  font-family: lato;
  text-align: center;
  font-size: 16px;
  color:rgb(134, 134, 134);
}

.BibliotecaTemas > .popup > .main-container > .item-card > .item-card-subtitle{
  font-family: lato;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color:rgb(164,164,164);
  margin-top: -1px;
}

.BibliotecaTemas > .popup > .footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 196px 1fr;
  box-shadow: 0 1px 7px 0 #888;
}

.BibliotecaTemas > .popup > .footer > .footer-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.BibliotecaTemas > .popup > .footer > .footer-left > .footer-label {
  position: absolute;
  font-family: lato;
  font-weight: bold;
  text-align: center;
  top: 12px;
  font-size: 22px;
  color: rgb(134, 134, 134);
}

.BibliotecaTemas > .popup > .footer > .footer-selected{
  display: flex;
}