.BibliotecaTemas > .popup > .main-container-category > .item-card{
  cursor: pointer;
}

.BibliotecaTemas > .popup > .main-container-category{
  padding: 20px 20px 0 20px;
  display: grid;
  grid-gap: 1.15vh 0.8vw;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  justify-content: space-between;
  grid-auto-rows: 120px;
  overflow-y: auto;
 }

 .BibliotecaTemas > .popup > .main-container-category > .item-card{
  display: flex;
  align-items: center;
  justify-content: center;
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  outline: 1px solid rgb(204,204,204);
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle:hover{
  outline: 1px solid rgb(134,134,134);
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle:active{
  outline: 2px solid rgb(255,106,4);
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle > .item-img{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  opacity: 1;
  position: relative;
  background: white;
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle > .item-img:hover{
  opacity: 0.75;
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle .bt-menu-selected{
  position: absolute;
  top: 2px;
  left: 2px;
}

.BibliotecaTemas > .popup > .main-container-category > .item-card > .item-rectangle .bt-menu-delete{
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: auto;
}