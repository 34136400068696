@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.error-alert .load-spinner {
  -webkit-transform-origin: 102px 102px;
  -ms-transform-origin: 102px 102px;
  transform-origin: 102px 102px;
  -webkit-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
  height: 204px;
}

.error-alert .logo {
  height: 98px;
  display: block;
  margin: auto;
}

.error-alert .spinner {
  display: block;
  margin: auto;
}

.error-alert .msg-label {
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  min-width: 250px;
  color: #666;
  text-align: center;
  font-size: 38px;
  margin: 30px 0 0;
}
