.btn-close-modal{
  z-index: 778 !important;
  position: absolute;
  top: 13px;
  right: 13px;
}

.modal-change-format {
  z-index: 777 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-change-format > .container {
  position: absolute;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 750px;
  height: 500px;
  background: #fff;
  display: inline-block;
}

.modal-change-format > .container >.image{
  position: relative;
  height: 250px;
}

.modal-change-format > .container > .image > button {
  position: absolute;
  margin-top: 114px;
}

.modal-change-format > .container > .formats {
  width: 100%;
  height: 200px;
  text-align: center;
  padding: 0 37px 0 37px;
}

.modal-change-format > .container > .formats > .title {
  display: block;
  font-weight: bold;
  padding: 23px 0 1px 0;
  font-size: 22px;
  color: #868686;
}

.modal-change-format > .container > .formats > .message {
  font-size: 20px;
  margin-top: -1px;
  color: #a4a4a4;
}

.modal-change-format > .container > .formats > button {
  margin: 15px;
  margin-top: 20px;
  padding-top: 1px;
  width: 200px;
  height: 36px;
  font-weight: 'normal';
  font-size: 22px;
}

.modal-change-format > .container > .formats > .select-product{
  margin-top: 6px;
  font-size: 20px;
}
