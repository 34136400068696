.button {
  background-color: rgba(0, 0, 0, 0);
  line-height: 1.42857143;
  font-size: 24px;
  font-weight: 700;
  border: none;
  margin: 2px;
  font-family: lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  cursor: pointer;
  border-radius: 0;
  padding: 2px 15px;
  outline: 0;
  color: #fff;
}

.no-padding {
  padding: 0;
}

.button.theme-blue {
  background-color: #00a9e7;
}

.button.theme-blue:hover,
.button.theme-blue:focus,
.background,
.button.theme-orange {
  background-color: #ff9807;
}

.button.theme-orange-disabled {
  background-color: #ff9807;
  opacity: 0.25;
  cursor: normal;
  pointer-events: none;
}

/* .button.theme-orange:active */
.button.theme-blue:active,
.button.theme-orange:hover,
.button.theme-orange:focus {
  background-color: #ff6a04;
}

.button.theme-grey {
  background-color: #aeaeae;
  opacity: 1;
}

.button.theme-grey:hover {
  background-color: #868686;
  opacity: 1;
}

.button.theme-grey:active {
  background-color: #ff6a04;
  opacity: 1;
}

.button.theme-grey-disabled {
  background-color: #aeaeae;
  opacity: 0.25;
  cursor: normal;
  pointer-events: none;
}
