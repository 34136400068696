.loader .logo,
.loader .load-spinner {
  position: absolute;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader .load-spinner {
  transform-origin: 102px 102px;
  animation: rotating 2s linear infinite;
  height: 204px;
}

.loader .logo {
  height: 98px;
  margin: 55px;
}

.loader .spinner {
  width: 204px;
  height: 204px;
  display: block;
  margin: auto;
}

.loader-min .load-spinner {
  transform-origin: 77px 77px;
  height: 154px;
}

.loader-min .logo {
  height: 76px;
  margin: 39px;
}

.loader-min .spinner {
  width: 154px;
  height: 154px;
}
