.photobook {
  display: flex;
}

.photobook.bookOverviewExpanded {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.photobook.hidden {
  display: none;
}

.photobook .navigator-modal {
  width: auto;
  height: 50vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
