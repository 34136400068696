.bt-menu-group-photo-mask {
  background-image: url('./agrupar/agrupar_mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 82px;
  height: 60px;
}

.cover:hover .bt-menu-group-photo-mask {
  background-image: url('./agrupar/agrupar_mask_mouse_over.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 82px;
  height: 60px;
}

.cover:active .bt-menu-group-photo-mask {
  background-image: url('./agrupar/agrupar_mask_pressionado.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 82px;
  height: 60px;
}

.icon-group-all-photos-mask {
  background-image: url('./agrupar/dropdown/todas_mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-all-photos-mask {
  background-image: url('./agrupar/dropdown/todas_mask_mouse_over.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.icon-group-all-photos-mask-checked,
label:active .icon-group-all-photos-mask {
  background-image: url('./agrupar/dropdown/todas_mask_pressionado.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.icon-group-used-photos-mask {
  background-image: url('./agrupar/dropdown/usadas_mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-used-photos-mask {
  background-image: url('./agrupar/dropdown/usadas_mask_mouse_over.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.icon-group-used-photos-mask-checked,
label:active .icon-group-used-photos-mask {
  background-image: url('./agrupar/dropdown/usadas_mask_pressionado.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.icon-group-multi-used-photos-mask {
  background-image: url('./agrupar/dropdown/multi_usadas_mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

label:hover .icon-group-multi-used-photos-mask {
  background-image: url('./agrupar/dropdown/multi_usadas_mask_mouse_over.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.icon-group-multi-used-photos-mask-checked,
label:active .icon-group-multi-used-photos-mask {
  background-image: url('./agrupar/dropdown/multi_usadas_mask_pressionado.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 33px;
  height: 26px;
}

.bt-menu-group-photo-mask {
  background-image: url('./agrupar/agrupar_mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 82px;
  height: 60px;
}

.bt-editor-duplicate:disabled:hover,
.bt-editor-duplicate {
  background-image: url('./duplicar/duplicar.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-duplicate:hover {
  background-image: url('./duplicar/duplicar_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-duplicate:active {
  background-image: url('./duplicar/duplicar_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-mais-5 {
  background-image: url('./girar/girar_mais_5.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-mais-5:hover {
  background-image: url('./girar/girar_mais_5_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-mais-5:active {
  background-image: url('./girar/girar_mais_5_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-menos-5 {
  background-image: url('./girar/girar_menos_5.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-menos-5:hover {
  background-image: url('./girar/girar_menos_5_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-menos-5:active {
  background-image: url('./girar/girar_menos_5_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-90 {
  background-image: url('./girar/girar_90.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-90:hover {
  background-image: url('./girar/girar_90_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-editor-girar-90:active {
  background-image: url('./girar/girar_90_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-fechar-biblioteca-temas {
  background-image: url('./fechar/fechar.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-fechar-biblioteca-temas:hover {
  background-image: url('./fechar/fechar_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-fechar-biblioteca-temas:active {
  background-image: url('./fechar/fechar_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-desselecionar-biblioteca-temas {
  background-image: url('./desselecionar/desselecionar.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-desselecionar-biblioteca-temas:hover {
  background-image: url('./desselecionar/desselecionar_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-desselecionar-biblioteca-temas:active {
  background-image: url('./desselecionar/desselecionar_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-selecionar-biblioteca-temas {
  background-image: url('./selecionar/selecionar.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-selecionar-biblioteca-temas:hover {
  background-image: url('./selecionar/selecionar_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-selecionar-biblioteca-temas:active {
  background-image: url('./selecionar/selecionar_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.bt-menu-add-theme-photo {
  background-image: url('./biblioteca/biblioteca.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 66px;
  height: 54px;
}

.bt-menu-add-theme-photo:hover {
  background-image: url('./biblioteca/biblioteca_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 66px;
  height: 54px;
}

.bt-menu-add-theme-photo:active {
  background-image: url('./biblioteca/biblioteca_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 66px;
  height: 54px;
}

.bt-menu-add-theme-photo-animated {
  background-image: url('./biblioteca/biblioteca_animated.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 66px;
  height: 54px;
}

.bt-bar-legenda {
  background-image: url('../legenda/botao_legenda.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 42px;
  height: 38px;
}

.bt-bar-legenda:hover {
  background-image: url('../legenda/botao_legenda_hover.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 42px;
  height: 38px;
}

.bt-bar-legenda-pressed,
.bt-bar-legenda:active {
  background-image: url('../legenda/botao_legenda_active.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 42px;
  height: 38px;
}

.bt-bar-legenda-disabled{
  background-image: url('../legenda/botao_legenda.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 42px;
  height: 38px;
  opacity: 0.25;
  cursor: normal;
  pointer-events: none;
}

.bt-bar-restore{
  background-image: url('../legenda/Icone-Seta-Esquerda-Editar-Teclado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 42px;
  height: 38px;
}

.bt-mover-baixo,
.bt-mover-baixo-inactive{
  background-image: url('./mover/mover_baixo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-mover-baixo:hover {
  background-image: url('./mover/mover_baixo_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-mover-baixo:active {
  background-image: url('./mover/mover_baixo_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-mover-cima,
.bt-mover-cima-inactive {
  background-image: url('./mover/mover_cima.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-mover-cima:hover {
  background-image: url('./mover/mover_cima_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-mover-cima:active {
  background-image: url('./mover/mover_cima_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 36px;
  height: 36px;
}

.bt-configuracao,
.bt-configuracao-inactive{
  background-image: url('./configuracao/configuracao.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.bt-configuracao:hover {
  background-image: url('./configuracao/configuracao_mouse_over.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.bt-configuracao:active {
  background-image: url('./configuracao/configuracao_pressionado.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
}

.bt-fundo {
  background-image: url('./fundo/botao_fundo.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 45px;
  height: 41px;
}

.bt-fundo:hover {
  background-image: url('./fundo/botao_fundo_hover.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 45px;
  height: 41px;
}

.bt-fundo-active,
.bt-fundo:active {
  background-image: url('./fundo/botao_fundo_active.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 45px;
  height: 41px;
}